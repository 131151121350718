import React, { useEffect, useState } from 'react';
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "@/components/ui/sheet";
import { Button } from '@/components/ui/button';
import PriceRangeAndChartMobile from '@/components/search-results/PriceRangeAndChartMobile';
import FilterLabel from '@/components/search-results/FilterLabel';
import Filters from './Filters';
import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from '@/components/ui/separator';
import { Loader2 } from 'lucide-react'; // Assuming you're using lucide-react for icons

interface FilterDetails {
    propertyTypes?: Set<string>;
}

const FilterSheet = ({ filters = [], open, closeFilter, onFiltersChange, count, handleRating }: {
    filters: FilterDetails[],
    open: boolean,
    count: number,
    closeFilter: (open: boolean) => void,
    handleRating: (rating: any) => void,
    onFiltersChange: (processedFilters: Record<string, Set<number>>) => void;
}) => {
    const [activeRating, setActiveRating] = useState<number | null>(null);
    const [propertyType, setPropertyType] = useState<Record<string, Set<number>>[]>([]);

    const handleRatingClick = (rating: any) => {
        handleRating((prev: number | null) => (prev === rating ? null : rating));
    };

    const handleFilterChange = (processedFilters: Record<string, Set<number>>) => {
        console.log('processed filters::', processedFilters);
        onFiltersChange(processedFilters);
        if (processedFilters.propertyTypes) {
            const propertyTypeArray = Array.from(processedFilters.propertyTypes);
            setPropertyType((prev: any) =>
                JSON.stringify(prev) !== JSON.stringify(propertyTypeArray) ? propertyTypeArray : prev
            );
        }
    };


    return (
        <Sheet open={open}>
            <SheetContent className="bg-[#F7F7F7] p-0 pb-24 min-w-full h-full font-poppins">
                <ScrollArea className="h-full max-h-screen">
                    <div className='w-full h-max'>
                        <SheetHeader className="px-5">
                            <SheetTitle className="text-base text-left">Filters</SheetTitle>
                            <Separator />
                        </SheetHeader>
                        <div className='px-5'>
                            <div className="relative py-5 border-b">
                                <SheetTitle className="text-base text-left">Your budget (per night)</SheetTitle>
                                <PriceRangeAndChartMobile />
                            </div>
                            <div className="flex-col justify-between items-start gap-2 py-5 border-b w-full">
                                <SheetTitle className="text-base text-left">Property rating</SheetTitle>
                                <div className="flex flex-col gap-3 mt-2">
                                    {[1, 2, 3, 4, 5].map((rating) => (
                                        <FilterLabel
                                            key={rating}
                                            description={`${rating} Star${rating > 1 ? 's' : ''}`}
                                            value=""
                                            id={`${rating}-rating`}
                                            onSelect={() => handleRatingClick(rating)}
                                            category={'rating'} />
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="px-5">
                            <Filters filterData={filters} onFiltersChange={handleFilterChange} isMobile={true} />
                        </div>
                    </div>
                </ScrollArea>

                <div
                    className="-bottom-0 fixed flex justify-between items-center bg-white px-5 py-3 w-full"
                    style={{
                        boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
                    }}
                >
                    <Button className="bg-transparent shadow-none text-black">Clear All</Button>
                    <Button className="py-6" onClick={() => { closeFilter(!open); }}>
                        Show {count} places
                    </Button>
                </div>
            </SheetContent>
        </Sheet>
    );
};

export default FilterSheet;
