"use client";
import React, { useEffect, useRef, useState } from "react";
import Logo from "@/public/Logo.png";
import { Button } from "@/components/ui/button";
import UserAvatar from "@/components/navigation/UserAvatar";
import Image from "next/image";
import coverImage from "@/public/search-results/background.png";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import ResultCard from "./ResultCard";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "@/components/ui/card";
import StarRating from "@/components/hotel-single-view/StartsRating";
import Filters from "./Filters";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/navigation";
import { getFilterDetails, getFilteredProperties } from "@/actions/services/getFilters";
import SearchByDateFilterPage from "@/components/Filters/filter-page/SearchByDateFilterPage";
import SearchByGuestCountFilterPage from "@/components/Filters/filter-page/SearchByGuestCountFilterPage";
import { SearchByLocationFilterPage } from "@/components/Filters/filter-page/SearchByLocationFilterPage";
import DefaultImage from "@/public/shared/DefaultLocation.png";
import { FaFilter, FaRandom, FaSearch } from "react-icons/fa";
import DynamicBreadCrumb from "./DynamicBreadCrumb";
import { getPlaceDetails } from "@/lib/google";
import FiltersSkeleton from "./skeletons/FiltersSkeleton";
import ResultsCountSkeleton from "./skeletons/ResultsCountSkeleton";
import ResultCardSkeleton from "./skeletons/ResultCardSkeleton";
import FilterSheet from "./FilterSheet";
import { useLenis } from "lenis/react"
import SortByDrawer from "./SortByDrawer";
import { Separator } from "@/components/ui/separator";
import { X } from "lucide-react";
import MobileSearchContainer from "./MobileSearchContainer";
import { DateRange } from "react-day-picker";
import { format } from "date-fns";
import { SortingTypes } from "@/common/constants";
import { openLoginModal } from "@/store/reducers/loginModal";

export const formatDate = (date: any) => format(date, "yyyy-MM-dd'T'HH:mm:ss");

interface ResultPageProps {
    destination: string;
    checkin: string;
    checkout: string;
    no_adults: number;
    no_rooms: number;
    no_children: number;
    pets: boolean;
    place_id: string;
}

interface FilterDetails {
    propertyTypes?: Set<string>;
    highlights?: Set<string>;
    amenities?: Set<string>;
    bedTypes?: Set<string>;
}

interface Property {
    propertyName: string;
    image?: string;
    unitDetails?: {
        price: number;
        originalPrice?: number;
        leftUnitCount?: number;
        unitCategoryName?: string;
        bedDetails?: string[];
    };
    discount?: number;
    summaryReview: {
        averageReviews: number;
        totalReviews: number;
    };
}

const ResultPage: React.FC<ResultPageProps> = ({
    destination,
    checkin,
    checkout,
    no_adults,
    no_rooms,
    no_children,
    pets,
    place_id,
}) => {
    const [hasSession, setHasSession] = useState<boolean>(false);
    const [activeRating, setActiveRating] = useState<number | null>(null);
    const [filters, setFilters] = useState<FilterDetails[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [fetchingProperties, setFetchingProperties] = useState<boolean>(true);
    const [fetchingNextPage, setFetchingNextPage] = useState<boolean>(false);
    const [selectedLocationDetails, setSelectedLocationDetails] = useState<any>(null);
    const [breadCrumbDetails, setBreadCrumbDetails] = useState<any>(null);
    const [selectedLocationDetailsFilter, setSelectedLocationDetailsFilter] = useState<any>(null);
    const [selectedPlaceId, setSelectedPlaceId] = useState<any>(null);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [propertiesToShow, setPropertiesToShow] = useState<Property[]>([]);
    const [propertiesCount, setPropertiesCount] = useState<number>(0);
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [openSortbyDrawer, setOpenSortbyDrawer] = useState<boolean>(false);
    const [hasNextPage, setHasNextPage] = useState<boolean>(true)
    const [initialFilters, setInitialFilters] = useState<boolean>(true)
    const [minVal, setMinVal] = useState<number>(5)
    const [checkIn, setCheckIn] = useState<any>(checkin)
    const [checkOut, setCheckOut] = useState<any>(checkout)
    const [maxVal, setMaxVal] = useState<number>(10000)
    const [selectedSortFilter, setSelectedSortFilter] = useState<string>('Our top picks');
    const [selectedValue, setSelectedValue] = useState<string>(SortingTypes[0].value);
    const dispatch = useDispatch();
    const router = useRouter();
    const lenis = useLenis()
    const stickyRef = useRef<HTMLDivElement>(null);
    const [isSticky, setIsSticky] = useState(false);
    const loadMoreRef = useRef<HTMLDivElement | null>(null);
    const observer = useRef<IntersectionObserver | null>(null);
    const [counts, setCounts] = useState({ adults: 1, children: 0, rooms: 1, pets: false });
    const filterState = useSelector((state: any) => state.filters);


    const handleCount = (adults: number, children: number, rooms: number, pets: boolean) => {
        setCounts(prevCounts => ({
            ...prevCounts,
            adults: adults,
            children: children,
            rooms: rooms,
            pets: pets,
        }));
    };

    useEffect(() => {
        setCounts(prevCounts => ({
            ...prevCounts,
            adults: no_adults,
            children: no_children,
            rooms: no_rooms,
            pets: pets,
        }));
    }, [no_adults, no_children, no_rooms, pets]);

    useEffect(() => {
        const initialize = async () => {
            await Promise.all([checkSessionStatus(), loadFilters(), getLocationDetails()]);
        };
        initialize();
        if (place_id == 'undefined') {
            fetchProperties({ filtered: false });
        }
    }, [place_id]);

    const closeFilterSheet = (open: boolean) => {
        setOpenFilter(open);
        setOpenSortbyDrawer(open);
    };
    const handleSortByFilter = (value: string) => {
        setSelectedSortFilter(value)
    };

    useEffect(() => {
        if (openFilter) {
            lenis?.stop()
        } else {
            lenis?.start()
        }
    }, [openFilter, lenis])

    const loadFilters = async () => {
        try {
            const res = await getFilterDetails();
            setFilters(res.data);
        } catch (error) {
            console.error("Error loading filters:", error);
        } finally {
            setLoading(false);
        }
    };

    const getLocationDetails = async () => {
        if (place_id == 'undefined' || undefined) {
            return;
        }

        try {
            const response = await getPlaceDetails(place_id);
            setSelectedLocationDetails(response);
            setBreadCrumbDetails(response);
        } catch (error) {
            console.error("Error fetching location details:", error);
        }
    };

    useEffect(() => {
        if (selectedLocationDetails) {
            fetchProperties({ filtered: false });
        }
    }, [selectedLocationDetails])

    useEffect(() => {
        setPageNumber(1)
        setCheckIn(checkin)
        setCheckOut(checkout)
    }, [])

    const fetchProperties = async ({ filtered = false }: { filtered?: boolean }) => {
        setPageNumber(1)
        try {
            setFetchingProperties(true);

            const dataObject = {
                location: filtered ? selectedLocationDetailsFilter : selectedLocationDetails,
                propertyTypeIds: filterState?.propertyTypes || [],
                highlights: filterState?.highlights || [],
                minMaxVal: {
                    min: minVal,
                    max: maxVal,
                },
                amenityIds: filterState?.amenities || [],
                adults: counts.adults || 1,
                children: counts.children || 0,
                activeRating: activeRating,
                checkIn: checkIn || new Date(),
                checkOut: checkOut || (new Date().getDate() + 1),
                bedTypeIds: filterState?.bedTypes || [],
                page: 0,
                sortType: selectedValue,
            }
            const response = await getFilteredProperties(dataObject);

            const currentUrl = new URL(window.location.href);

            currentUrl.searchParams.set("no_adults", counts.adults.toString() || '');
            currentUrl.searchParams.set("no_children", counts.children.toString() || '');
            currentUrl.searchParams.set("no_rooms", counts.rooms.toString() || '');
            currentUrl.searchParams.set("pets", counts.pets.toString() || 'false');

            window.history.replaceState(null, "", currentUrl.toString());

            setHasNextPage(response?.pagination?.hasNextPage || false);

            setPropertiesToShow(response.data || []);

            setPropertiesCount(response?.pagination?.totalCount || 0);

            if (!filtered) {
                setInitialFilters(false)
            }
        } catch (error) {
            console.error("Error fetching properties:", error);
        } finally {
            setFetchingProperties(false);
        }
    };

    const handleSearchParams = () => {
        const currentUrl = new URL(window.location.href);

        currentUrl.searchParams.set("destination", selectedLocationDetailsFilter?.name || '');
        currentUrl.searchParams.set("place_id", selectedPlaceId || '');;

        window.history.replaceState(null, "", currentUrl.toString());
        fetchProperties({ filtered: true });
    };

    useEffect(() => {

        if (initialFilters) {
            return;
        }
        if (selectedLocationDetailsFilter === null) {
            fetchProperties({ filtered: false });
            return;
        }

        setSelectedLocationDetails(null);
        fetchProperties({ filtered: true });


    }, [filterState, activeRating, selectedValue])

    const nextPage = async () => {
        if (!hasNextPage) {
            return;
        }
        try {
            setFetchingNextPage(true);
            const response = await getFilteredProperties({
                location: selectedLocationDetailsFilter === null ? selectedLocationDetails : selectedLocationDetailsFilter,
                propertyTypeIds: filterState?.propertyTypes || [],
                highlights: filterState?.highlights || [],
                minMaxVal: {
                    min: minVal,
                    max: maxVal,
                },
                amenityIds: filterState?.amenities || [],
                checkIn: checkIn || new Date(),
                checkOut: checkOut || (new Date().getDate() + 1),
                adults: counts.adults || 1,
                children: counts.children || 0,
                bedTypeIds: filterState?.bedTypes || [],
                activeRating: activeRating,
                page: pageNumber,
                sortType: selectedValue,
            });
            const currentUrl = new URL(window.location.href);

            currentUrl.searchParams.set("no_adults", counts.adults.toString() || '');
            currentUrl.searchParams.set("no_children", counts.children.toString() || '');
            currentUrl.searchParams.set("no_rooms", counts.rooms.toString() || '');
            currentUrl.searchParams.set("pets", counts.pets.toString() || 'false');

            window.history.replaceState(null, "", currentUrl.toString());
            if (response) {
                setHasNextPage(response?.pagination?.hasNextPage || false);

                setPropertiesToShow((prevProperties) => [
                    ...prevProperties,
                    ...(response?.data || []),
                ]);

                setPropertiesCount(response?.pagination?.totalCount || 0);

                setPageNumber((prevPage) => prevPage + 1);
            }

        } catch (error) {
            console.error("Error fetching properties:", error);
        } finally {
            setFetchingNextPage(false);
        }
    }

    useEffect(() => {
        if (!hasNextPage) {
            return;
        }

        observer.current = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    nextPage();
                }
            },
            { threshold: 1.0 }
        );

        return () => {
            observer.current?.disconnect();
        };
    }, [hasNextPage, fetchingProperties, fetchingNextPage]);

    useEffect(() => {
        if (!hasNextPage) {
            return;
        }

        if (loadMoreRef.current) {
            observer.current?.observe(loadMoreRef.current);
        }

        return () => {
            if (loadMoreRef.current) {
                observer.current?.unobserve(loadMoreRef.current);
            }
        };
    }, [propertiesToShow]);

    const checkSessionStatus = async () => {
        try {
            const response = await fetch("/api/session/check", { method: "POST" });
            const result = await response.json();
            setHasSession(result.success);
        } catch (error) {
            console.error("Error checking session:", error);
        }
    };

    const onChangeDate = (date: DateRange | undefined) => {

        const currentUrl = new URL(window.location.href);

        currentUrl.searchParams.set("checkin", date?.from ? formatDate(date.from) : '');
        currentUrl.searchParams.set("checkout", date?.to ? formatDate(date.to) : '');
        setCheckIn(date?.from ? formatDate(date.from) : undefined)
        setCheckOut(date?.to ? formatDate(date.to) : undefined)

        window.history.replaceState(null, "", currentUrl.toString());
    };

    const handleFilterChange = (processedFilters: FilterDetails) => {
        // const updateState = (filterKey: keyof FilterDetails, setter: React.Dispatch<React.SetStateAction<any[]>>) => {
        //     if (processedFilters[filterKey]) {
        //         const newArray = Array.from(processedFilters[filterKey] as any).filter(Boolean);
        //         setter((prev) => (JSON.stringify(prev) !== JSON.stringify(newArray) ? newArray : prev));
        //     }
        // };

        // updateState("propertyTypes", setPropertyType);
        // updateState("highlights", setHighlights);
        // updateState("amenities", setAmenityIds);
        // updateState("bedTypes", setBedTypeIds);
    };

    const handleRatingClick = (rating: number) => {
        setActiveRating((prev) => (prev === rating ? null : rating));
    };

    useEffect(() => {
        const handleScroll = () => {
            if (stickyRef.current) {
                const rect = stickyRef.current.getBoundingClientRect();
                if (rect.top <= 0) {
                    setIsSticky(true);
                } else {
                    setIsSticky(false);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleCountChange = () => {

        fetchProperties({ filtered: false });
    }

    const handleMobileRating = (rating: number) => {
        setActiveRating(rating)
    }

    return (
        <>
            <section className="relative flex flex-col items-center bg-[#F7F7F7] p-10 max-[1000px]:p-3 max-[1030px]:p-5 max-[1700px]:p-8 max-[1730px]:p-8 w-full font-poppins">
                <div className="relative max-[1000px]:p-0 px-48 max-[1030px]:px-5 max-[1700px]:px-10 max-[1730px]:px-32 w-full h-full">
                    <nav className="flex justify-between items-center w-full">
                        <Image
                            loading="lazy"
                            src={Logo}
                            alt="Serviced Apartments Logo"
                            // width={150}
                            className="w-[150px] max-[1000px]:w-[120px] max-[1030px]:w-[130px] cursor-pointer"
                            onClick={() => router.push("/")}
                        />
                        <div>
                            {!hasSession ? (
                                <Button
                                    className="bg-primary hover:bg-transparent bg-opacity-5 border border-primary border-opacity-50 rounded-2xl w-20 font-poppins text-primary"
                                    onClick={() => dispatch(openLoginModal())}
                                >
                                    Login
                                </Button>
                            ) : (
                                <UserAvatar />
                            )}
                        </div>
                    </nav>

                    <div className="relative mt-5 border rounded-[3rem] max-[1000px]:rounded-2xl w-full h-[48vh] min-h-[25rem] max-[1000px]:min-h-[15rem] max-[450px]:min-h-[10rem] max-[1000px]:max-h-[15rem] overflow-hidden">
                        <Image
                            src={coverImage}
                            alt={'Serviced Apartments'}
                            fill
                            style={{ objectFit: "cover" }}
                            priority
                        />
                        <div className="absolute inset-0 bg-gradient-to-t from-[#0B161F] via-[transparent] max-[1000px]:via-[#0B161F] to-transparent opacity-50 max-[1000px]:opacity-40"></div>
                        <h1 className="top-8 max-[1000px]:top-0 left-8 max-[1000px]:left-0 absolute max-[1000px]:p-5 max-[450px]:p-3 w-[55%] max-[1000px]:w-full max-[1180px]:w-[90%] max-[1264px]:w-[75%] max-[1858px]:w-[69%] font-medium max-[1000px]:font-semibold text-white max-[307px]:text-[1.3rem] max-[326px]:text-[1.5rem] max-[373px]:text-[1.6rem] max-[450px]:text-3xl max-[583px]:text-4xl max-[1000px]:text-5xl text-7xl text-wrap">
                            Effortless Hotel Bookings with Serviced Apartments
                        </h1>

                        <div className="max-[1000px]:hidden bottom-10 left-[50%] absolute flex justify-between items-center bg-white bg-opacity-50 backdrop-blur-sm border rounded-full w-[80%] max-[1030px]:w-[95%] max-[1870px]:w-[90%] h-16 -translate-x-[50%]">
                            <div className="left-5 z-2 relative flex flex-row items-center w-[85%] font-poppins">
                                <div>
                                    <SearchByLocationFilterPage
                                        onLocationDetailsChange={(details: any) => {
                                            setSelectedLocationDetailsFilter(details);
                                            setSelectedLocationDetails(null);
                                            setBreadCrumbDetails(details);
                                        }}
                                        defaultLocation={destination == 'null' ? null : destination}
                                        selectedPlaceId={(id: any) => setSelectedPlaceId(id)}
                                    />
                                </div>
                                <div>
                                    <SearchByDateFilterPage defaultDateRange={{ from: checkIn, to: checkOut }} onChange={onChangeDate} />
                                </div>
                                <div>
                                    <SearchByGuestCountFilterPage defaultCount={{ adult: counts.adults, children: counts.children, room: counts.rooms, pets: counts.pets }} onChangeCounts={handleCount} />
                                </div>
                            </div>
                            <div className="flex justify-center items-center pr-2 w-[14%]">
                                <Button
                                    className="flex justify-center items-center bg-secondary py-3 rounded-[84px] w-full h-full text-center transition-all duration-300 cursor-pointer"
                                    onClick={() => handleSearchParams()}
                                >
                                    <FaSearch className="text-white" />
                                    <span className="font-poppins font-normal text-sm">Search</span>
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="hidden max-[1000px]:flex flex-col justify-center items-center -mt-16 w-full h-max">
                        <MobileSearchContainer onLocationDetailsChange={function (details: any): void {
                            setSelectedLocationDetailsFilter(details);
                            setSelectedLocationDetails(null)
                            setBreadCrumbDetails(details);
                        }}
                            defaultLocation={destination} handleSearchParams={handleSearchParams} />
                    </div>


                    {
                        selectedPlaceId !== undefined &&
                        selectedPlaceId !== 'undefined' && (
                            <DynamicBreadCrumb selectedLocationDetails={breadCrumbDetails} />
                        )
                    }




                </div>
            </section>

            <div className="top-0 z-10 sticky bg-[#F7F7F7] pb-3" style={{
                boxShadow: isSticky ? 'rgba(0, 0, 0, 0.16) 0px 1px 4px' : 'none',
                paddingTop: isSticky ? '10px' : '0',
            }} ref={stickyRef}>
                <div className="hidden max-[1000px]:flex justify-start items-center bg-[#F7F7F7] w-full font-poppins">
                    <Button onClick={() => setOpenFilter(true)} className="bg-transparent hover:bg-transparent focus:bg-transparent shadow-none font-light text-primary"><FaFilter /> Filter</Button>
                    <Button onClick={() => setOpenSortbyDrawer(true)} className="bg-transparent hover:bg-transparent focus:bg-transparent shadow-none font-light text-primary"><FaRandom /> SortBy</Button>

                    <SortByDrawer open={openSortbyDrawer} closeFilter={closeFilterSheet} handleChange={handleSortByFilter} />
                    <FilterSheet filters={filters} open={openFilter} closeFilter={closeFilterSheet} onFiltersChange={handleFilterChange} count={propertiesCount} handleRating={handleMobileRating} />
                </div>
                <Separator />
                <div className="hidden scrollbar-hidden gap-2 bg-[#F7F7F7] px-3 pt-3 w-full h-max overflow-x-auto font-poppins whitespace-nowrap">
                    <div className="flex justify-between items-center gap-2 bg-secondary bg-opacity-15 px-3 py-1 border border-secondary rounded-2xl w-max h-min text-secondary text-sm">
                        {selectedSortFilter} <X className="w-4 h-4" />
                    </div>
                    <div className="flex justify-between items-center gap-2 bg-primary bg-opacity-15 px-3 py-1 border border-primary rounded-2xl w-max h-min text-primary text-sm">
                        {selectedSortFilter}
                    </div>
                </div>
            </div>


            <section className="flex flex-col items-center bg-[#F7F7F7] max-[1000px]:p-0 px-10 max-[1030px]:px-5 max-[1700px]:px-8 w-full font-poppins">
                <div className="flex justify-between items-start max-[1000px]:p-0 px-48 max-[1030px]:px-5 max-[1700px]:px-8 w-full">
                    <div className="max-[1000px]:hidden w-2/6">
                        {loading ? (
                            <>
                                <FiltersSkeleton />
                            </>
                        ) : (
                            <>
                                <Card className="w-full">
                                    <CardHeader className="border-b">
                                        <CardTitle>Filter by</CardTitle>
                                    </CardHeader>
                                    <CardContent>
                                        <div className="py-5 border-b">
                                            <span className="font-medium text-sm">Budget (per night)</span>

                                            <div className="flex justify-between items-center mt-3 w-full">
                                                <div className="relative w-full min-w-32 max-w-60">
                                                    <span className="top-1/2 left-3 absolute text-gray-500 -translate-y-1/2">$</span>
                                                    <input
                                                        className="!px-2 !py-3 pl-6 border border-primary border-opacity-40 !rounded-lg min-w-32 max-w-60 !text-sm text-center"
                                                        type="number"
                                                        value={minVal}
                                                        onChange={(e) => {
                                                            const value = parseInt(e.target.value, 10);
                                                            if (!isNaN(value)) {
                                                                setMinVal(value);
                                                            }
                                                        }}
                                                        onBlur={() => {
                                                            if (minVal < 5) {
                                                                setMinVal(5);
                                                            }
                                                            if (maxVal <= minVal) {
                                                                setMaxVal(minVal + 1);
                                                            }
                                                        }}
                                                        min={5}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                e.preventDefault();
                                                                document.getElementById("applyButton")?.click();
                                                            }
                                                        }}
                                                    />
                                                </div>

                                                <div className="relative w-full min-w-32 max-w-60">
                                                    <span className="top-1/2 left-3 absolute text-gray-500 -translate-y-1/2">$</span>
                                                    <input
                                                        className="!px-2 !py-3 pl-6 border border-primary border-opacity-40 !rounded-lg min-w-32 max-w-60 !text-sm text-center"
                                                        type="number"
                                                        value={maxVal}
                                                        onChange={(e) => {
                                                            const value = parseInt(e.target.value, 10);
                                                            if (!isNaN(value)) {
                                                                setMaxVal(value);
                                                            }
                                                        }}
                                                        onBlur={() => {
                                                            if (maxVal <= minVal) {
                                                                setMaxVal(minVal + 1);
                                                            }
                                                        }}
                                                        min={minVal + 1}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                e.preventDefault();
                                                                document.getElementById("applyButton")?.click();
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <Button
                                                id="applyButton"
                                                className="bg-secondary mt-3 w-full font-normal"
                                                onClick={() => {
                                                    if (maxVal < minVal + 1) {
                                                        setMaxVal(minVal + 1);
                                                    }
                                                    handleCountChange();
                                                }}
                                            >
                                                Apply
                                            </Button>
                                        </div>




                                        <div className="flex-col justify-between items-start gap-2 py-5 border-b w-full lex">
                                            <span className="font-medium text-sm">Rating</span>
                                            <div>
                                                {[1, 2, 3, 4, 5].map((rating) => (
                                                    <div
                                                        key={rating}
                                                        onClick={() => handleRatingClick(rating)}
                                                        className={`flex items-center gap-3 hover:border-[#FFECEC]  px-2 py-3 border ${rating === 5
                                                            ? 'rounded-bl-xl rounded-br-xl'
                                                            : rating === 1
                                                                ? 'rounded-tl-xl rounded-tr-xl'
                                                                : 'border-b-0'
                                                            } w-full duration-100 cursor-pointer ${activeRating === rating ? 'active-rating-filter hover:bg-secondary' : 'hover:bg-[#FFECEC]'
                                                            } justify-start items-center`}
                                                    >
                                                        <span className='font-light text-sm'>{rating}</span>
                                                        <StarRating
                                                            rating={rating}
                                                            className={activeRating === rating ? 'text-white' : 'text-secondary'}
                                                            size={16}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        <Filters filterData={filters} onFiltersChange={handleFilterChange} />
                                    </CardContent>
                                </Card>
                            </>
                        )
                        }
                    </div >

                    <div className="w-full">
                        {fetchingProperties ? (<>

                            <ResultsCountSkeleton />

                        </>) : (
                            <div className="max-[1000px]:hidden flex justify-between ml-5">
                                {propertiesCount > 0 ? (
                                    <span>
                                        {breadCrumbDetails?.name ? breadCrumbDetails?.name + ':' : ''} {propertiesCount} search results found
                                    </span>
                                ) : (
                                    <span>No properties to show</span>
                                )}
                                <Select value={selectedValue} onValueChange={setSelectedValue}>
                                    <SelectTrigger className="w-2/6">
                                        <SelectValue placeholder="Select Value" />
                                    </SelectTrigger>
                                    <SelectContent className="font-poppins">
                                        {SortingTypes.map((option, index) => (
                                            <SelectItem key={index} value={option.value}>
                                                {option.label}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>
                        )}


                        <div className="mt-10 max-[1000px]:mt-0">
                            {fetchingProperties ? (
                                <div className="max-[1000px]:place-items-center gap-0 max-[1000px]:gap-10 grid max-[1000px]:grid-cols-2 max-[675px]:grid-cols-1">
                                    {Array.from({ length: propertiesCount > 0 ? propertiesCount : 3 }).map((_, index) => (
                                        <ResultCardSkeleton key={index} />
                                    ))}
                                </div>
                            ) : (
                                <div className="place-items-center gap-0 grid max-[1000px]:grid-cols-2 max-[675px]:grid-cols-1">
                                    {propertiesToShow.map((room: any, index) => {
                                        console.log('Rooms Detailssss: ', room)
                                        return (
                                            <ResultCard
                                                property={room || ''}
                                                checkIn={checkIn}
                                                count={counts}
                                                checkOut={checkOut}
                                                key={room?.propertyId || index}
                                                ref={index === propertiesToShow.length - 1 ? loadMoreRef : null}
                                                title={room.propertyName}
                                                image={room?.propertyImage?.file?.mediumPath || DefaultImage}
                                                price={room?.totalPrice || 0}
                                                discount={room.discount}
                                                originalPrice={room?.discountPrice}
                                                unitDetails={room?.unitDetails || []}
                                                leftUnitCount={room.unitDetails?.leftUnitCount || 0}
                                                averageReviews={room.summaryReview.averageReviews}
                                                totalReviews={room.summaryReview.totalReviews}
                                                unitCategoryName={room?.unitDetails?.unitCategoryName || []}
                                                bedDetails={room.unitDetails?.bedDetails || []}
                                                description={room?.propertyDescription || ''}
                                                cancellationPolicy={room?.cancellationPolicy || []}
                                            />
                                        )
                                    })}
                                    {fetchingNextPage && (
                                        <ResultCardSkeleton />
                                    )}
                                </div>
                            )}
                        </div>


                    </div>
                </div >
            </section >
        </>
    );
};

export default ResultPage;
