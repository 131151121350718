import React, { useEffect, useState } from 'react'
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from "@/components/ui/breadcrumb"
import Link from 'next/link';
import BreadCrumbSkeleton from './skeletons/BreadCrumbSkeleton';
import { useMediaQuery } from 'react-responsive';

interface AddressComponent {
    long_name: string;
    short_name: string;
    types: string[];
}

interface SelectedLocationDetails {
    address_components: AddressComponent[];
}
const DynamicBreadCrumb = ({ selectedLocationDetails }: { selectedLocationDetails: SelectedLocationDetails }) => {

    const [breadcrumbData, setBreadcrumbData] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const isMobile = useMediaQuery({ maxWidth: 450 });

    const generateBreadcrumbData = (addressComponents: AddressComponent[]) => {
        const hierarchy = isMobile ? ["country", "administrative_area_level_1", "locality"] : ["country", "administrative_area_level_1", "administrative_area_level_2", "locality"];
        const breadcrumbItems: string[] = [];

        hierarchy.forEach((type) => {
            const item = addressComponents.find((component) => component.types.includes(type));
            if (item) {
                breadcrumbItems.push(item.long_name);
            }
        });

        return breadcrumbItems;
    };

    useEffect(() => {
        if (selectedLocationDetails?.address_components) {
            const breadcrumbItems = generateBreadcrumbData(selectedLocationDetails.address_components);
            setBreadcrumbData(breadcrumbItems);
            setLoading(false)
        }
    }, [selectedLocationDetails]);

    return (
        <>
            {loading ? (<>
                <BreadCrumbSkeleton />
            </>) : (
                <Breadcrumb className="mt-5 max-[450px]:mt-3 font-poppins">
                    <BreadcrumbList className='max-[450px]:gap-[0.5] max-[450px]:text-xs'>
                        <BreadcrumbItem>
                            <BreadcrumbLink asChild>
                                <Link href="/">Home</Link>
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        {breadcrumbData.map((item, index) => (
                            <React.Fragment key={index}>
                                <BreadcrumbItem>
                                    {index === breadcrumbData.length - 1 ? (
                                        <BreadcrumbPage>{item}</BreadcrumbPage>
                                    ) : (
                                        <BreadcrumbLink asChild>
                                            <Link href="/">{item}</Link>
                                        </BreadcrumbLink>
                                    )}
                                </BreadcrumbItem>
                                {index !== breadcrumbData.length - 1 && <BreadcrumbSeparator />}
                            </React.Fragment>
                        ))}
                    </BreadcrumbList>
                </Breadcrumb>
            )}
        </>
    )
}

export default DynamicBreadCrumb