"use client";

import React, { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { format, isValid, parseISO, set } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { DateRange } from "react-day-picker";
import { toZonedTime } from "date-fns-tz";

const parseDate = (dateStr: string | undefined) => {
    if (!dateStr) return undefined;
    const parsedDate = parseISO(dateStr);
    return isValid(parsedDate) ? parsedDate : undefined;
};

const timeZone = "Asia/Colombo";

const SearchByDateFilterPage = ({ defaultDateRange, onChange }: { defaultDateRange: { from?: string; to?: string }; onChange: (date: DateRange | undefined) => void }) => {
    const [date, setDate] = useState<DateRange | undefined>({
        from: parseDate(defaultDateRange?.from),
        to: parseDate(defaultDateRange?.to),
    });

    const handleDateChange = (newDate: DateRange | undefined) => {
        if (!newDate) return;

        const adjustedRange: DateRange = {
            from: newDate.from
                ? toZonedTime(set(newDate.from, { hours: 0, minutes: 0, seconds: 0 }), timeZone)
                : undefined,
            to: newDate.to
                ? toZonedTime(set(newDate.to, { hours: 23, minutes: 59, seconds: 59 }), timeZone)
                : undefined,
        };
        setDate(adjustedRange);
        console.log('adjustedRange: ', adjustedRange);
        onChange(adjustedRange);
    };

    // const handleSelect = (newDate: DateRange | undefined) => {
    //     if (!newDate) return;
    
    //     // Normalize date comparisons using .getTime()
    //     const isSameFrom = date?.from && newDate?.from && date.from.getTime() === newDate.from.getTime();
    //     const isSameTo = date?.to && newDate?.to && date.to.getTime() === newDate.to.getTime();
    
    //     let updatedRange: DateRange = { from: newDate.from, to: newDate.to };
    
    //     if (date?.from && date?.to) {
    //         if (isSameFrom && newDate.to) {
    //             updatedRange = { from: newDate.to, to: undefined }; // Shift range forward
    //         } else if (!isSameFrom && newDate.to) {
    //             updatedRange = { from: newDate.from, to: undefined }; // Reset with new from date
    //         }
    //     } else if (date?.from && !date?.to) {
    //         if (isSameFrom && newDate.to) {
    //             updatedRange = { from: date.from, to: newDate.to }; // Complete the range
    //         } else {
    //             updatedRange = { from: newDate.from, to: newDate.to }; // Update normally
    //         }
    //     } else if (!date?.from && date?.to) {
    //         updatedRange = { from: newDate.from, to: date.to }; // Ensure 'from' is set
    //     }
    
    //     setDate(updatedRange);
    // };

    // useEffect(() => {
    //     if (!date) return;

    //     const adjustedRange: DateRange = {

    //         from: date.from
    //             ? toZonedTime(set(date.from, { hours: 0, minutes: 0, seconds: 0 }), timeZone)
    //             : undefined,

    //         to: date.to
    //             ? toZonedTime(set(date.to, { hours: 23, minutes: 59, seconds: 59 }), timeZone)
    //             : undefined,
    //     };

    //     console.log('Updated Date Range:', adjustedRange);
    //     onChange(adjustedRange);
    // }, [date]);

    useEffect(() => {
        if (defaultDateRange) {
            setDate({
                from: parseDate(defaultDateRange?.from),
                to: parseDate(defaultDateRange?.to),
            });
        }
    }, [defaultDateRange]);

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    id="date"
                    variant={"outline"}
                    className={cn(
                        "justify-start hover:bg-transparent hover:scale-[1.02] duration-100 transition-transform text-left font-normal max-[1315px]:text-sm border-0 text-sm w-[300px] max-[1342px]:w-[270px] max-[1267px]:w-[290px] max-[1671px]:w-[320px] max-[1870px]:w-[320px] max-[1730px]:w-[340px] max-[1195px]:w-[250px] max-[1108px]:w-[240px] py-6 bg-transparent border-r rounded-none border-[#807e7e]",
                        !date?.from && "text-muted-foreground"
                    )}
                >
                    <CalendarIcon />
                    {date?.from ? (
                        date.to ? (
                            <>
                                {format(date.from, "LLL dd, y")} -{" "}
                                {format(date.to, "LLL dd, y")}
                            </>
                        ) : (
                            format(date.from, "LLL dd, y")
                        )
                    ) : (
                        <span>Pick a date</span>
                    )}
                </Button>
            </PopoverTrigger>
            <PopoverContent className="p-0 w-auto" align="start">
                <Calendar
                    initialFocus
                    mode="range"
                    selected={date}
                    onSelect={handleDateChange}
                    numberOfMonths={2}
                    disabled={(date: any) => date < new Date().setHours(0, 0, 0, 0)}
                    // onSelect={(date: DateRange | undefined) => handleSelect(date)}
                    className="custom-calendar"
                />
            </PopoverContent>
        </Popover>
    );
};

export default SearchByDateFilterPage;
