export let VERIFY_CODE = 'verify_code';
export let BASIC_AUTH = 'basic_auth';
export let REFRESH_TOKEN = 'refresh_token';
export let ACCESS_TOKEN = 'access_token';
export let CSRF_TOKEN_KEY = 'csrf_token';

export const PROPERTY_TYPES = {
    APARTMENT: 'apartment',
    HOTEL: 'hotel',
    ROOM: 'room',
}

export enum PAYMENT_TYPES {
    PAY_AT_PROPERTY = 'PAY_AT_PROPERTY',
    CARD = 'CARD',
}
export enum PAYMENT_STATUS_TYPES {
    SUCCESS = 'SUCCESS',
    CANCELLED = 'CANCELLED',
    PENDING = 'PENDING',
    FAILED = 'FAILED',

}

export enum RESERVATION_STATUS_TYPES {
    PENDING = 'PENDING',
    REJECTED = 'REJECTED',
}

export type TransactionType = {
    reservationId: number,
    amount: number,
    currency: string,
    redirectUrl: string,
    customerName: string,
    customerEmail: string,

}

export type AccessType = 'CUSTOMER_EMAIL_SIGN_UP' | 'ADMIN' | 'SUPER_ADMIN';
export type PASSWORDRESETACTIONTYPE = 'RESET_PASSWORD' | 'UPDATE_PASSWORD';

interface SortingType {
    label: string;
    value: string;
}

export const SortingTypes: SortingType[] = [
    { label: "Price (lowest first)", value: "PRICE_ASC" },
    { label: "Price (highest first)", value: "PRICE_DESC" },
    { label: "Property rating (low to high)", value: "RATING_ASC" },
    { label: "Property rating (high to low)", value: "RATING_DESC" },
];
