import React, { useState } from "react";
import { Checkbox } from "../ui/checkbox";
import { Label } from "../ui/label";
import { Button } from "../ui/button";
import { useDispatch, useSelector } from "react-redux";
import { addAmenity, addBedType, addHighlight, addPropertyType, removeAmenity, removeBedType, removeHighlight, removePropertyType } from "@/store/reducers/filterReducer";

interface FilterDetails {
  propertyTypes?: Set<string>;
  highlights?: Set<string>;
  amenities?: Set<string>;
  bedTypes?: Set<string>;
}

interface FilterLabelProps {
  description: string;
  value: string;
  isDynamic?: boolean;
  id: string;
  category: any;
  onSelect: (id: string, category: keyof FilterDetails) => void;
}
 
const FilterLabel: React.FC<FilterLabelProps> = ({
  description,
  value,
  isDynamic = false,
  id,
  category,
  onSelect,
}) => {
  const [count, setCount] = useState<number>(0);
  const dispatch = useDispatch();
  const filters = useSelector((state: any) => state.filters);

  const numericValue = isDynamic ? parseInt(value, 10) || 0 : 0;

  const increment = () => setCount((prev) => Math.min(numericValue, prev + 1));
  const decrement = () => setCount((prev) => Math.max(0, prev - 1));

  // const handleCheckboxChange = () => {
  //   const extractedCategory = category.split("-")[0];
  //   onSelect(id, extractedCategory);
  // };

  const handleCheckboxChange = () => {
    const extractedCategory = category.split("-")[0];
    const extractedId = Number(category.split("-")[1]);

    if (filters[extractedCategory]?.includes(extractedId)) {
      switch (extractedCategory) {
        case "propertyTypes":
          dispatch(removePropertyType(extractedId));
          break;
        case "highlights":
          dispatch(removeHighlight(extractedId));
          break;
        case "amenities":
          dispatch(removeAmenity(extractedId));
          break;
        case "bedTypes":
          dispatch(removeBedType(extractedId));
          break;
        default:
          break;
      }
    } else {
      switch (extractedCategory) {
        case "propertyTypes":
          dispatch(addPropertyType(extractedId));
          break;
        case "highlights":
          dispatch(addHighlight(extractedId));
          break;
        case "amenities":
          dispatch(addAmenity(extractedId));
          break;
        case "bedTypes":
          dispatch(addBedType(extractedId));
          break;
        default:
          break;
      }
    }
    // onSelect(id, extractedCategory);
  };

  return (
    <div className="flex justify-between items-center w-full">
      <div className="flex justify-between items-center space-x-2 w-full">
        <span className="flex flex-row justify-center items-center gap-2 hover:cursor-pointer">
          <Checkbox
            id={id}
            onClick={handleCheckboxChange}
            className="data-[state=checked]:bg-secondary border-secondary"
          />
          <Label htmlFor={id} className="font-light text-sm hover:cursor-pointer">
            {description}
          </Label>
        </span>
        {isDynamic ? (
          <div className="flex items-center space-x-2">
            <Button
              variant="outline"
              size="icon"
              className="w-8 h-8"
              onClick={decrement}
              disabled={count <= 0}
            >
              -
            </Button>
            <span className="font-light text-sm">{count}</span>
            <Button
              variant="outline"
              size="icon"
              className="w-8 h-8"
              onClick={increment}
              disabled={count >= numericValue}
            >
              +
            </Button>
          </div>
        ) : (
          <span className="font-light text-sm">{value}</span>
        )}
      </div>
    </div>
  );
};

export default FilterLabel;
